import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from './config';
import { toast } from 'react-toastify';
import Header from './components/Header';
import { Link, Outlet, useLocation } from 'react-router';

const DashboardLayout = () => {
    const location = useLocation();
    const [currentMenu, setCurrentMenu] = useState(location.pathname.replace('/dashboard', '').replace('/', ''));
    const [user, setUser] = useState([]);
    const [token, setToken] = useState([]);


    useEffect(() => {
        setToken(localStorage.getItem('authToken'))
        fetchApi()
    }, []);

    const fetchApi = async () => {
        await axios.get(
            `${config.apiUrl}get/user/`,
            {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                setUser(response.data)
            } else {
                toast.error('Something Wrong!');
            }
        }).catch((error) => {
            toast.error(error);
        })
    }

    const menus = [
        { name: 'Dashboard', path: '' },
        { name: 'Category', path: 'category' },
        { name: 'Rack', path: 'rack' },
        { name: 'Book', path: 'book' },
    ]

    return (
        <>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-2" style={{ maxHeight: '90vh', backgroundColor: '#CED3DC' }}>
                        <ul class="list-group">
                            {menus.map((menu) => {
                                return (
                                    <Link to={menu.path} onClick={() => { setCurrentMenu(menu.path) }} className={currentMenu == menu.path ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'}>{menu.name}</Link>

                                )
                            })}
                        </ul>
                    </div>
                    <div className="col-10" style={{ height: '90vh', backgroundColor: '#f8f9fa' }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardLayout;
