import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../config';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router';

const Header = () => {
    const [user, setUser] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        fetchApi()
    }, []);

    const fetchApi = async () => {
        await axios.get(
            `${config.apiUrl}get/user/`,
            {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                setUser(response.data)
            } else {
                toast.error('Something Wrong!');
            }
        }).catch((error) => {
            toast.error(error);
        })
    }

    const handleLogout = () => {
        localStorage.removeItem('authToken')
        navigate("/login");
    }

    return (
        <nav className="navbar " style={{ background: "#36F1CD" }}>
            <div className="container-fluid">
                <Link to={'/dashboard'} className="navbar-brand"><h5>Library</h5></Link>
                <div className="d-flex" style={{ marginRight: '100px' }}  >
                    <h6 className='mt-3 me-3'>Hi, {user.first_name} {user.last_name}</h6>
                    <div className="dropdown-center">
                        <img src={"https://cdn1.iconfinder.com/data/icons/avatar-3/512/Manager-512.png"} width="50px" height={"50px"} alt='avater' type="button" data-bs-toggle="dropdown" />
                        <ul className="dropdown-menu">
                            <li className='px-2' type="button" onClick={handleLogout}>Logout</li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
