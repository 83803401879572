import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router';
import config from '../config';
import { toast } from 'react-toastify';

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });

    let navigate = useNavigate();

    const handelSubmit = async (e) => {
        e.preventDefault()
        await axios.post(
            `${config.apiUrl}auth/login/`,
            formData
        ).then((response) => {
            if (response.data.token) {
                localStorage.setItem('authToken', response.data.token);
                toast.success('Login successfully.');

                navigate("/dashboard");

            } else {
                toast.error('Something Wrong!');
            }
        }).catch((error) => {
            toast.error(error.response.data.error);
        })

    }

    const handelFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div style={{ height: '100vh', paddingTop: 100, background: '#CED3DC' }}>
            <div className="container col-md-5">
                <div class="card">
                    <div class="card-body">
                        <h3 className='card-title text-center'>Login</h3>
                        <form className='px-5 py-3' onSubmit={handelSubmit}>
                            <div className="my-3">
                                <input type="text" className="form-control" name='username' placeholder='Enter username' value={formData.username} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="password" className="form-control" name='password' placeholder='Enter password' value={formData.password} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                        I'm don't have account <Link to={'/register'} className='text-end'>Register</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
