import React from 'react';

const Rack = () => {
    return (
        <div>
            Rack
        </div>
    );
}

export default Rack;
