
import './App.css';
import { Routes, Route, Outlet, Navigate } from "react-router";
import Login from './pages/Login';
import Register from './pages/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardLayout from './DashboardLayout';
import Category from './pages/dashboard/Category';
import Rack from './pages/dashboard/Rack';
import Book from './pages/dashboard/Book';

// Protected route for authenticated users
const ProtectedRoute = () => {
  const token = localStorage.getItem("authToken");
  return token ? <Outlet /> : <Navigate to={'/login'} />;
};

// Protected route for public pages (e.g., login/register)
const PublicRoute = () => {
  const token = localStorage.getItem("authToken");
  return !token ? <Outlet /> : <Navigate to={'/dashboard'} />;
};

function App() {

  return (
    <>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="category" element={<Category />} />
            <Route path="rack" element={<Rack />} />
            <Route path="book" element={<Book />} />
          </Route>
        </Route>
      </Routes >
      <ToastContainer />
    </>
  );
}

export default App;
