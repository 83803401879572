import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router';
import config from '../config';
import { toast } from 'react-toastify';

const Register = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        password: '',
        confirm_password: ''
    });

    let navigate = useNavigate();

    const handelSubmit = async (e) => {
        e.preventDefault()
        await axios.post(
            `${config.apiUrl}auth/register/`,
            formData
        ).then((response) => {
            if (response.status === 201) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                toast.error('Something Wrong!');
            }
        }).catch((error) => {
            toast.error(error.response.data.error);
        })

    }

    const handelFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div style={{ height: '100vh', paddingTop: 50, background: '#CED3DC' }}>
            <div className="container col-md-5">
                <div class="card">
                    <div class="card-body">
                        <h3 className='card-title text-center'>Register</h3>
                        <form className='px-5 py-3' onSubmit={handelSubmit}>
                            <div className="my-3">
                                <input type="text" className="form-control" name='first_name' placeholder='Enter first name' value={formData.first_name} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="text" className="form-control" name='last_name' placeholder='Enter last name' value={formData.last_name} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="email" className="form-control" name='email' placeholder='Enter email' value={formData.email} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="text" className="form-control" name='username' placeholder='Enter username' value={formData.username} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="password" className="form-control" name='password' placeholder='Enter password' value={formData.password} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <div className="my-3">
                                <input type="password" className="form-control" name='confirm_password' placeholder='Enter confirm password' value={formData.confirm_password} onChange={(e) => { handelFormData(e) }} />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                        I'm already user <Link to={'/login'} className='text-end'>Login</Link>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Register;
