import React from 'react';

const Category = () => {
    return (
        <div>
            Category
        </div>
    );
}

export default Category;
